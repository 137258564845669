// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
    {
        id: 1,
        path: 'aws-certification-workshop',
        title: 'AWS Subject Matter Expert Workshop',
        category: 'Cloud Infrastructure & DevOps',
        img: require('@/assets/images/aws-workshop/SME-Image-2.jpg'),
        date: 'March 28, 2024',
        projectImages: [
            {
                id: 1,
                img: require('@/assets/images/aws-workshop/1711998807343.jpg'),
            },
            {
                id: 2,
                img: require('@/assets/images/aws-workshop/SME-Image-1.jpg'),
            },
            {
                id: 3,
                img: require('@/assets/images/aws-workshop/1711998810168.jpg'),
            },
        ],
        projectInfo: {
            clientHeading: 'Workshop Details',
            companyInfos: [
                {
                    id: 1,
                    title: 'Objective',
                    details: 'Develop items for AWS Certification exams'
                }
            ],
            objectivesHeading: 'Workshop Description',
            objectivesDetails:
                'AWS Certification Item Development Workshop in Seattle.',
            technologies: [
                {
                    title: 'Tools & Technologies',
                    techs: [
                        'AWS',
                    ],
                },
            ],
            projectDetailsHeading: 'Workshop Experience',
            projectDetails: [
                {
                    id: 1,
                    details:
                        'The workshop was a great opportunity to meet and work with experts from different countries.' +
                        ' We worked on the development of AWS Certification exams, focusing on creating and reviewing exam content.'
                },
                {
                    id: 2,
                    details:
                        'As a Subject Matter Expert, one is involved in defining the topics to be covered in the exam,' +
                        ' developing and evaluating exam questions, and determining the passing score for the exam.' +
                        ' I can\'t reveal any details about the exam content, but I can say that it was a fun experience to work on the development of an AWS Certification exam. ' +
                        ' I got to know quite a bunch of talented people from AWS and other industry leading companies, which I had great conversations with.'
                },
                {
                    id: 3,
                    details:
                        'The experience was enriching and I look forward to participating in similar workshops in the future.' +
                        ' As a small token of appreciation, AWS gifted us a picnic blanket with the AWS logo.' +
                        ' I found it kinda funny, because I don\'t really know what a bunch of nerds would do with a picnic blanket, but it might have been' +
                        ' a hint to go outside and touch some grass for a change :D'
                },
                {
                    id: 4,
                    details:
                        'I am looking forward to the next workshop and the opportunity to contribute to the development of AWS Certification exams.' +
                        ' If you are interested in joining the AWS SME program, you can find more information on the AWS website, I can highly recommend it.'
                },
            ],
            socialSharingsHeading: 'Share the experience',
            socialSharings: [
                {
                    id: 1,
                    name: 'LinkedIn',
                    icon: 'linkedin',
                    url: 'https://linkedin.com/',
                },
            ],
        },
    },
    {
        id: 2,
        path: 'project-2',
        title: 'Image processing with Diffusion Models',
        category: 'Machine Learning & Data Science',
        img: require('@/assets/images/Img2Img_Title.jpg'),
        date: 'January 7, 2023',
        projectImages: [
            {
                id: 1,
                img: require('@/assets/images/img2img/og.jpeg'),
            },
            {
                id: 2,
                img: require('@/assets/images/img2img/inpunk_website4.jpg'),
            },
            {
                id: 3,
                img: require('@/assets/images/img2img/inkpunk viktor.jpg'),
            },

        ],

        projectInfo: {
            clientHeading: 'Project Details',
            companyInfos: [
                {
                    id: 1,
                    title: 'Project',
                    details: 'Image processing with Deep Learning'
                }
            ],
            objectivesHeading: 'Task Description',
            objectivesDetails:
                'Creation of a tool for image processing using Deep Learning.',
            technologies: [
                {
                    title: 'Tools & Technologies',
                    techs: [
                        'Golang',
                        'Python',
                        'JavaScript',
                        'PyTorch',
                        'Vue.js',
                        'FastAPI',
                        'Echo',
                        'PocketBase',
                        'HuggingFace',
                        'Tailwind CSS',
                        'Docker',
                        'Azure',
                        'Kubernetes',
                        'Flowbite'
                    ],
                },
            ],
            projectDetailsHeading: 'Project Description',
            projectDetails: [
                {
                    id: 1,
                    details:
                        'With this project, I set out to build a web tool that allows for image adjustment using the Deep Learning technique' +
                        ' "Stable Diffusion". The tool was hosted on the cloud platform Azure using the Kubernetes solution AKS. I provided the original Stable Diffusion model' +
                        ' as well as some finely tuned variations. The user can then choose from the different models. ' +
                        'I recommend anyone interested in the functionality and architecture of Deep Learning models to read the paper "High-resolution image synthesis with latent diffusion models" by Rombach et al. 2022.'
                },
                {
                    id: 4,
                    details:
                        'Above this blog post, three images are displayed, two of which were edited using this tool: the original image on the left, an adjusted image in the ' +
                        'middle, and a more heavily modified image on the right. '
                },
                {
                    id: 2,
                    details:
                        'For the backend, I used Golang and the PocketBase package to implement authentication, authorization, and an API gateway to the other services.' +
                        ' I used Python for machine learning (with PyTorch and HuggingFace Transformers) and implemented the user interface with the Vue.js frontend framework.' +
                        ' Azure Service Bus was used to communicate between different services, as I used my "on-premise" system for Deep Learning.',
                },
                {
                    id: 3,
                    details:
                        'One of my most important learnings during this project was how important it is to have a well-structured architecture' +
                        ' and organization of the codebase. Since I was responsible for both the backend, frontend, and fine-tuning of the machine learning models,' +
                        ' I had to ensure that all components were cleanly separated from each other and easy to maintain.',
                },
                {
                    id: 5,
                    details:
                        'UPDATE: The tool is currently offline, as my Azure account has expired and I do not plan to renew it. ' +
                        'However, I am currently working on a new version of the tool, which will be available soon. '
                }

            ],
            socialSharingsHeading: 'Share the project',
            socialSharings: [
                {
                    id: 1,
                    name: 'LinkedIn',
                    icon: 'linkedin',
                    url: 'https://linkedin.com/',
                },
            ],
        },
    },
    {
        id: 1,
        path: 'project-1',
        title: 'Personal website created with Vue.js',
        category: 'Software Development',
        img: require('@/assets/images/website_project.png'),
        date: 'January 10, 2023',
        projectImages: [
            {
                id: 1,
                img: require('@/assets/images/personal_website/personal_website_2.jpg'),
            },
            {
                id: 2,
                img: require('@/assets/images/personal_website/personal_website_3.jpg'),
            },
            {
                id: 3,
                img: require('@/assets/images/personal_website/personal_website_1.jpg'),
            },

        ],
        projectInfo: {
            clientHeading: 'Project Details',
            companyInfos: [
                {
                    id: 1,
                    title: 'Project',
                    details: 'Built a personal website with Vue.js'
                }
            ],
            objectivesHeading: 'Task Description',
            objectivesDetails:
                'I wanted to upgrade my GitHub pages website to a more modern and responsive website.',
            technologies: [
                {
                    title: 'Tools & Technologies',
                    techs: [
                        'JavaScript',
                        'Vue.js',
                        'Tailwind CSS',
                        'Netlify',
                    ],
                },
            ],
            projectDetailsHeading: 'Project Description',
            projectDetails: [
                {
                    id: 1,
                    details:
                        'I embarked on a journey to revamp my GitHub Pages presence into a modern and responsive website while delving into the Vue.js and Tailwind CSS ecosystem. ' +
                        'The goal was to create an intuitive platform for sharing my work and experiences.'

                },
                {
                    id: 2,
                    details:
                        'Vue.js proved to be the perfect choice for this project due to its user-friendly nature and exceptional data-passing capabilities between components. ' +
                        'As you can see it was quite easy to leverage Vue.js to create a seamless and interactive user experience.',

                },
                {
                    id: 3,
                    details:
                        'To kickstart the development process, I utilized the Vue.js Tailwind CSS portfolio repository by Nangialai Stoman  as a foundation. ' +
                        'This open-source resource provided a solid starting point, saving some initial time and effort.',
                },
                {
                    id: 4,
                    details:
                        'The website deployment was done via Netlify, ensuring that the site is always accessible and responsive to users on the web. ' +
                        'Feel to explore the website and share your thoughts with me.',
                },
                {
                    id: 5,
                    details:
                        'In the last image  at the top you can also see the frontend for my image processing tool that I also hosted on this website.' +
                        ' I since removed it because my Azure Free Tier had ran out, which I used to host the backend.',
                },
            ],
            socialSharingsHeading: 'Share the project',
            socialSharings: [
                {
                    id: 1,
                    name: 'LinkedIn',
                    icon: 'linkedin',
                    url: 'https://linkedin.com/',
                },
            ],
        },
    },
];

export default projects;

